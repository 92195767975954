<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="col-12">
        <next-matchs :lastMatches="matches" :show-details="true" />
      </div>
      <div class="col-12">
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="paginate"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NextMatchs from '../../partials/NextMatchs'
import * as moment from 'moment'

export default {
  components: {
    NextMatchs
  },
  data () {
    return {
      matches: [],
      currentPage: 1,
      rows: 0,
      perPage: 0
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'teamFront'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'fixture')
    this.fetchMatches()
  },
  methods: {
    paginate(page) {
      const params = {
        page
      }
      this.$axios.get(this.path, { params }).then((response) => {
        const data = response.data.data
        this.matches = data.data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
      })
    },
    fetchMatches() {
      const lang = this.$route.params.lang
      const cons = this.$route.params.console
      const slug = this.$route.params.teamSlug
      this.path = `${lang}/console/${cons}/team/${slug}/last-matches`
      this.$axios.get(this.path).then((response) => {
        const data = response.data.data
        this.matches = data.data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
      })
    }
  }
}
</script>
